<template>
    <div>
        <div style="display: flex; align-items: center;">
            <a-button @click="UpdateOrders()" class="ml-5">
                <a-icon type="reload" theme="outlined" />
                    Refresh
            </a-button>
            <div v-if="exchange_name === 'bithumb'" style="margin-left: 10px;">
                <strong>* 원화 출금내역은 지원하지 않습니다.</strong>
            </div>
            <div v-if="exchange_name === 'bitget'" style="margin-left: 10px;">
                <strong>* 현재 시간으로부터 일주일(7 days) 이내 출금내역 조회 가능</strong>
            </div>
            <div v-if="exchange_name === 'lbank'" style="margin-left: 10px;">
                <strong>* 현재 시간으로부터 90일(90 days) 이내 출금내역 조회 가능</strong>
            </div>
            <div v-if="exchange_name === 'coinbase'" style="margin-left: 10px;">
                <strong>* Wallet 선택 시 출금내역 조회 가능, 수수료 정보 지원하지 않음</strong>
            </div>
            <div v-if="exchange_name === 'mexc'" style="margin-left: 10px;">
                <strong>* 현재 시간으로부터 일주일(7 days) 이내 출금내역 조회 가능</strong>
            </div>
        </div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="orders_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true"
                     :scroll="{ y: 1200 }">
                <template slot="timestamp" slot-scope="text">
                    <!-- {{ $moment(Number(text)).format('YYYY-MM-DD HH:mm:ss') }} -->
                    {{ text }}
                </template>
                <template slot="is_ask" slot-scope="text">
                    {{ text === false ? "BUY" : "SELL" }}
                </template>
                <template slot="amount" slot-scope="text, record">
                    {{ Number(record.price * record.amount).toLocaleString(undefined, { maximumFractionDigits: 5 }) }}
                </template>
            </a-table>
		</a-card>
    </div>
</template>
<script>
// const ExchangeName = 'bithumb';
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
        network: String,
        exchange_name: String,
        account_id: String
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: this.$i18n.t('asset_movement.withd_date'),
                    dataIndex: 'timestamp',
                    sorter: (a, b) => {
                        if (this.exchange_name === 'bithumb') {
                            return a.transfer_date - b.transfer_date
                        } else if (this.exchange_name === 'binance') {
                            return new Date(a.applyTime) - new Date(b.applyTime)
                        } else if (this.exchange_name === 'bitget') {
                            return a.cTime - b.cTime
                        } else if (this.exchange_name === 'lbank') {
                            return a.applyTime - b.applyTime
                        } else if (this.exchange_name === 'coinbase') {
                            return new Date(a.created_at) - new Date(b.created_at)
                        } else if (this.exchange_name === 'lbank') {
                            return a.applyTime - b.applyTime
                        }
                    },
                    scopedSlots: { customRender: 'timestamp'}
                },
                {
                    title: this.$i18n.t('asset_movement.withd_condition'),
                    dataIndex: 'search',
                },
                {
                    title: this.$i18n.t('asset_movement.withd_name'),
                    dataIndex: 'order_currency',
                },
                {
                    title: this.exchange_name === 'bithumb'
                        ? this.$i18n.t('asset_movement.withd_amount')
                        : this.$i18n.t('asset_movement.withd_amount_binance'),
                    dataIndex: 'units',
                },
                {
                    title: this.$i18n.t('asset_movement.withd_fee'),
                    dataIndex: 'fee',
                },
                {
                    title: this.$i18n.t('asset_movement.withd_network'),
                    dataIndex: 'network',
                },
            ],
            // bithumb
            //  {
            //     "search": "5",
            //     "transfer_date": "1722405728211627",
            //     "order_currency": "USDT",
            //     "payment_currency": "KRW",
            //     "units": "- 10.0",
            //     "price": "0",
            //     "amount": "0",
            //     "fee_currency": "USDT",
            //     "fee": "0",
            //     "order_balance": "48.94204336",
            //     "payment_balance": "2"
            //   },
            // binance
            // {
            //   "id": "9487b13e7be04a539ae349100a62ba9f",
            //   "amount": "17.473656",
            //   "transactionFee": "1",
            //   "coin": "USDT",
            //   "status": 6,
            //   "address": "TWSXYaXQwK5G16wyPeMucX7yZJt7aornjU",
            //   "txId": "fea41aa453607f9028c262f3871773a959902c91aee51610f5aa90db2cad3345",
            //   "applyTime": "2024-09-02 06:45:03",
            //   "network": "TRX",
            //   "transferType": 0,
            //   "info": "broadcast:TQrY8tryqsYVCYS3MFbtffiPp2ccyn4STm",
            //   "confirmNo": 50,
            //   "txKey": "",
            //   "completeTime": "2024-09-02 06:46:44"
            // },
            // bitget
            //   {
            //     "orderId": "1215794024049422336",
            //     "tradeId": "5b3762c7ce63035d783a1ae23567fe00764bab5db13bdf26714363f146087497",
            //     "coin": "USDT",
            //     "type": "withdraw",
            //     "dest": "on_chain",
            //     "size": "27.33664111",
            //     "fee": "-1.00000000",
            //     "status": "success",
            //     "toAddress": "TNa8u5zakood2fSqmK58X5oP4iDCtP48nb",
            //     "chain": "TRX(TRC20)",
            //     "confirm": "2",
            //     "clientOid": null,
            //     "tag": null,
            //     "fromAddress": null,
            //     "cTime": "1725593804371",
            //     "uTime": "1725593951009"
            //   }
            // lbank 
            // {
            //   "amount": 13.8827,
            //   "address": "TDV5mf1r17MtssdPVWsGCD21bLdHRQ8NZm",
            //   "fee": 1,
            //   "networkName": "trc20",
            //   "coid": "usdt",
            //   "transferType": "数字资产提现",
            //   "txId": "7cbd7dc88aa9e8acdd7dfc8c35cdaa76d0f5a2c90fa600373ca76dfb729b1b1e",
            //   "id": 7086365,
            //   "applyTime": 1725338604000,
            //   "status": "4",
            //   "info": ""
            // },
            // coinbaase
            // {
            //   "amount": {
            //     "amount": "11.200000",
            //     "currency": "USDT"
            //   },
            //   "created_at": "2024-10-07T07:20:27Z",
            //   "id": "80c728f0-9ecd-5435-b5dc-d2bdb6f7ce0e",
            //   "native_amount": {
            //     "amount": "11.20",
            //     "currency": "USD"
            //   },
            //   "network": {
            //     "hash": "fda0e801ec44da8078300451f8993f408bb9a447eb1dd1ecda9b6fb8777442dc",
            //     "network_name": "ethereum",
            //     "status": "confirmed"
            //   },
            //   "resource": "transaction",
            //   "resource_path": "/v2/accounts/1d7f06b6-5947-597d-86df-b40d19680dd4/transactions/80c728f0-9ecd-5435-b5dc-d2bdb6f7ce0e",
            //   "status": "completed",
            //   "type": "send"
            // },
            // mexc
            // {
            //   "id": "ccefa6fd40de4aa483d72a0ff9329611",
            //   "txId": "4aad568b1d0973676f38db30d3eae1e34b111f35c85becacc008b2f892049458",
            //   "coin": "USDT-TRX",
            //   "network": "Tron(TRC20)",
            //   "address": "TCNnd1nDRs2GRg7rsR5RY52NK7o4h2kwnq",
            //   "amount": "38.81509",
            //   "transferType": 0,
            //   "status": 7,
            //   "transactionFee": "1",
            //   "confirmNo": null,
            //   "applyTime": 1734339095000,
            //   "remark": "",
            //   "memo": null,
            //   "explorerUrl": "https://tronscan.org/#/transaction/4aad568b1d0973676f38db30d3eae1e34b111f35c85becacc008b2f892049458",
            //   "transHash": "4aad568b1d0973676f38db30d3eae1e34b111f35c85becacc008b2f892049458",
            //   "updateTime": 1734339261000,
            //   "netWork": "TRX"
            // }
            orders_table: []
        }
    },
    methods: {
        getSymbol(){
            return `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`
        },
        async UpdateOrders(){
            let res = '';

            if (this.exchange_name === 'coinbase') {
                res = await this.$http.post(`/api/v1/exchange2/${this.exchange_name}/withdlist`, {
                    apikeyId: this.apikeyId,
                    running_server: this.running_server,
                    accountId: this.account_id,
                })
            } else {
                res = await this.$http.post(`/api/v1/exchange2/${this.exchange_name}/withdlist`, {
                    apikeyId: this.apikeyId,
                    symbol: this.getSymbol(),
                    running_server: this.running_server
                })
            }

            // console.log(res.data)
            this.orders_table = res.data
            let res_ = res.data;

            if (this.exchange_name === 'bithumb') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        const date = new Date(parseInt(item.transfer_date) / 1000); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 네트워크
                        item.network = this.network;

                        // 출금 상태
                        if (item.search === '3') {
                            item.search = '출금 중';
                        } else if (item.search === '5') {
                            item.search = '출금 완료';
                        } else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        const number = item.units
                        const number1 = number.split(' ')[1];
                        const number2 = number1.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                    });
                }
            } else if (this.exchange_name === 'binance') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        const date = new Date(item.applyTime);
                        date.setHours(date.getHours() + 9);
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); 
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');
                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        //fee 
                        item.fee = item.transactionFee;

                        // 0(0:Email Sent,1:Cancelled 2:Awaiting Approval 3:Rejected 4:Processing 5:Failure 6:Completed)
                        // 출금 상태
                        if (item.status === 0) {
                            item.search = 'Email Sent';
                        } else if (item.status === 1) {
                            item.search = 'Cancelled';
                        } else if (item.status === 2) {
                            item.search = 'Awaiting Approval';
                        } else if (item.status === 3) {
                            item.search = 'Rejected';
                        } else if (item.status === 4) {
                            item.search = 'Processing';
                        } else if (item.status === 5) {
                            item.search = 'Failure';
                        } else if (item.status === 6) {
                            item.search = 'Completed';
                        }else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        const number = item.amount
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                        // 토큰
                        item.order_currency = item.coin;
                    });
                }
            } else if (this.exchange_name === 'bitget') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        item.fTime = parseInt(item.cTime)
                        const date = new Date(item.fTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 토큰명
                        item.order_currency = item.coin;

                        // 네트워크
                        item.network = item.chain;

                        // 입금상태
                        item.search = item.status;

                        // 수수료
                        let fee_ = String(parseFloat(item.fee));
                        item.fee = parseFloat(fee_.replace(/[^0-9.]+/g, ''));

                        // 수량 표기 변환
                        const number = parseFloat(item.size) - item.fee;
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                    });
                }
            } else if (this.exchange_name === 'lbank') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        item.fTime = parseInt(item.applyTime)
                        const date = new Date(item.fTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // status ("1","Applying"),("2","Cancelled"),("3","Withdrawal failed"),("4","Withdrawal complete")
                        // 출금 상태
                        if (item.status === '1') {
                            item.search = 'Applying';
                        } else if (item.status === '2') {
                            item.search = 'Cancelled';
                        } else if (item.status === '3') {
                            item.search = 'failed';
                        } else if (item.status === '4') {
                            item.search = 'complete';
                        }else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        const number = item.amount
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                        // 토큰
                        item.order_currency = item.coid.toUpperCase();
                    });
                }
            } else if (this.exchange_name === 'coinbase') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        const originTime = item.created_at;
                        const date = new Date(originTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

                        item.timestamp = koreanTime;

                        // 토큰명
                        item.order_currency = item.amount.currency.toUpperCase();
                        
                        // 네트워크
                        item.network = item.network.network_name;

                        // 입금상태
                        item.search = item.status;

                        // 수량 
                        item.units = parseFloat(item.amount.amount) * -1;
                        item.units = Number(item.units).toLocaleString(undefined, { maximumFractionDigits: 8 });
                        
                        // 수수료
                        item.fee = '-';
                    });
                }
            } else if (this.exchange_name === 'mexc') {
                if (res_.length > 0) {
                    res_.forEach(item => {
                        // timestamp -> 한국시간
                        item.fTime = parseInt(item.applyTime)
                        const date = new Date(item.fTime); 
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        const seconds = String(date.getSeconds()).padStart(2, '0');

                        const koreanTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
                        item.timestamp = koreanTime;

                        // 출금 상태
                        if (item.status === 1) {
                            item.search = 'APPLY';
                        } else if (item.status === 2) {
                            item.search = 'AUDITING';
                        } else if (item.status === 3) {
                            item.search = 'WAIT';
                        } else if (item.status === 4) {
                            item.search = 'PROCESSING';
                        } else if (item.status === 5) {
                            item.search = 'WAIT_PACKAGING';
                        } else if (item.status === 6) {
                            item.search = 'WAIT_CONFIRM';
                        } else if (item.status === 7) {
                            item.search = 'SUCCESS';
                        } else if (item.status === 8) {
                            item.search = 'FAILED';
                        } else if (item.status === 9) {
                            item.search = 'CANCEL';
                        } else if (item.status === 10) {
                            item.search = 'MANUAL';
                        } else {
                            item.search = '???';
                        }

                        // 수량 표기 변환
                        const number = item.amount
                        // const number1 = number.split(' ')[1];
                        const number2 = number.toString().split('.');
                        number2[0] = number2[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        const number3 = number2.join('.');
                        item.units = number3;

                        // 토큰
                        item.order_currency = this.coin.toUpperCase();

                        // 수수료
                        item.fee = item.transactionFee;
                    });
                }
            }

            this.orders_table = res_;
        }
        
    },
    async mounted() {
        await this.UpdateOrders()
        this.updateInterval = setInterval(() => {
            this.UpdateOrders()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        symbol(v){
            UpdateOrders()
        }
    },

}
</script>

<style lang="scss">

</style>