<template>
  <!-- Main Sidebar -->
  <a-layout-sider
    collapsible
    class="sider-primary"
    breakpoint="lg"
    collapsed-width="0"
    width="250px"
    :collapsed="sidebarCollapsed"
    @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
    :trigger="null"
    :class="[
      'ant-layout-sider-' + sidebarColor,
      'ant-layout-sider-' + sidebarTheme,
    ]"
    theme="light"
    :style="{ backgroundColor: 'transparent' }"
  >
    <div class="brand">
      <img src="images/logo-ct-black.png" alt="" /> <span>Muse Mutex</span>
    </div>
    <hr />

    <!-- Sidebar Navigation Menu -->
    <a-menu
      theme="light"
      mode="inline"
      :open-keys="openKeys"
      @openChange="onOpenChange"
    >
      <a-menu-item class="menu-item-header"> APP </a-menu-item>

      <a-sub-menu key="CrossTrade" style="padding: 0">
        <span slot="title">
          <span class="icon">
            <a-icon type="dashboard" theme="filled" class="m-0" />
          </span>
          <span class="label">{{ $t("sidebar.autotrade") }}</span>
        </span>
        <a-menu-item-group>
          <a-menu-item>
            <router-link to="/pricenotification/list">
              <span class="label">{{ $t("sidebar.price_alert") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/crosstrade">
              <span class="label">{{ $t("sidebar.cross_trade") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/crosstrade_v2">
              <span class="label">{{ $t("sidebar.cross_trade_v2") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/crosstrade_v2_1">
              <span class="label">{{ $t("sidebar.cross_trade_v2_1") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/multi_crosstrade">
              <span class="label">{{ $t("sidebar.multi_crosstrade") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/central_control">
              <span class="label">{{ $t("sidebar.central_control") }}</span>
            </router-link>
          </a-menu-item>
          <!-- <a-menu-item>
							<router-link to="/autotrade/arbitrage">
								<span class="label">{{ $t("sidebar.arbitrage") }}</span>
							</router-link>
						</a-menu-item> -->
          <a-menu-item>
            <router-link to="/autotrade/fillblank">
              <span class="label">{{ $t("sidebar.auto_price") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/fillblank_v2">
              <span class="label">{{ $t("sidebar.auto_price_v2") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/fillblank_v3">
              <span class="label">{{ $t("sidebar.auto_price_v3") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/fillblank_central">
              <span class="label">{{ $t("sidebar.auto_price_central") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/fillblankfakewall">
              <span class="label">{{ $t("sidebar.fake_wall") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/scheduler/v1/dashboard">
              <span class="label">{{ $t("sidebar.scheduler") }}</span>
            </router-link>
          </a-menu-item>
          <!-- <a-menu-item>
            <router-link to="/scheduler/v2/dashboard">
              <span class="label">{{ $t("sidebar.scheduler_v2") }}</span>
            </router-link>
          </a-menu-item> -->
          <a-menu-item>
            <router-link to="/mm/dashboard">
              <span class="label">{{ $t("sidebar.mm") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/autotrade/countingbot">
              <span class="label">{{ $t("sidebar.countingbot") }}</span>
            </router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>

      <!-- <a-sub-menu key="ExchangeFuture" style="padding: 0">
        <span slot="title">
          <span class="icon">
            <a-icon type="dashboard" theme="filled" class="m-0" />
          </span>
          <span class="label">{{ $t("sidebar.trade_future") }}</span>
        </span>
        <a-menu-item-group>
          <a-menu-item>
            <router-link to="/exchange/binancefuture">
              <span class="label">Binance Future</span>
            </router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu> -->

      <a-sub-menu key="Bithumb" style="padding: 0">
        <span slot="title">
          <span class="icon">
            <a-icon type="dashboard" theme="filled" class="m-0" />
          </span>
          <span class="label">{{ $t("sidebar.trade") }}</span>
        </span>
        <a-menu-item-group>
          <a-menu-item>
            <router-link to="/exchange/bithumb">
              <span class="label">Bithumb</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/Bitkub">
              <span class="label">Bitkub</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/coinone">
              <span class="label">Coinone</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/lbank">
              <span class="label">Lbank</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/mexc">
              <span class="label">Mexc</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/huobi">
              <span class="label">Huobi</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/gateio">
              <span class="label">Gateio</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/okex">
              <span class="label">Okex</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/digifinex">
              <span class="label">Digifinex</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/bitmart">
              <span class="label">Bitmart</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/gopax">
              <span class="label">Gopax</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/xt">
              <span class="label">XT</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/poloniex">
              <span class="label">Poloniex</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/binance">
              <span class="label">Binance</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/whitebit">
              <span class="label">Whitebit</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/bitget">
              <span class="label">Bitget</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/coinstore">
              <span class="label">Coinstore</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/upbit">
              <span class="label">Upbit</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/kucoin">
              <span class="label">Kucoin</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/coinbase">
              <span class="label">Coinbase</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/bitflyer">
              <span class="label">Bitflyer</span>
            </router-link>
          </a-menu-item>

          <a-menu-item>
            <router-link to="/exchange/probit">
              <span class="label">Probit</span>
            </router-link>
          </a-menu-item>

          <!-- <a-menu-item>
            <router-link to="/exchange/gemini">
              <span class="label">Gemini</span>
            </router-link>
          </a-menu-item> -->
        </a-menu-item-group>
      </a-sub-menu>

      <a-sub-menu key="Deposit" style="padding: 0">
        <span slot="title">
          <span class="icon">
            <a-icon type="dashboard" theme="filled" class="m-0" />
          </span>
          <span class="label">{{ $t("sidebar.asset_movement") }}</span>
        </span>
        <a-menu-item-group>
          <a-menu-item>
            <router-link to="/assetmovement/deposit">
              <span class="label">{{ $t("sidebar.deposit") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/assetmovement/withdraw">
              <span class="label">{{ $t("sidebar.withdraw") }}</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/assetmovement/address">
              <span class="label">{{ $t("sidebar.address_list") }}</span>
            </router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>

      <a-menu-item class="menu-item-header"> Setting </a-menu-item>

      <a-sub-menu key="authentication" style="padding: 0">
        <span slot="title">
          <span class="icon">
            <a-icon type="dashboard" theme="filled" class="m-0" />
          </span>
          <span class="label">Regist</span>
        </span>
        <a-menu-item-group>
          <a-menu-item>
            <router-link to="/settings/users">
              <span class="label">Users</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/settings/apikey">
              <span class="label">ApiKey</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/settings/telegram">
              <span class="label">Telegram</span>
            </router-link>
          </a-menu-item>
          <a-menu-item>
            <router-link to="/settings/telegrambot">
              <span class="label">TelegramBot</span>
            </router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>

      <a-menu-item class="menu-item-header">
        <hr class="mt-5" />
      </a-menu-item>
      <!--
				<a-sub-menu key="dashboards" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="dashboard" theme="filled" class="m-0" />
						</span>
						<span class="label">Dashboards</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/dashboards/">
								<span class="label">Default</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/dashboards/crm">
								<span class="label">CRM</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>

				<a-menu-item class="menu-item-header">
					Pages
				</a-menu-item>

				<a-sub-menu key="pages" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="code" theme="filled" class="m-0" />
						</span>
						<span class="label">Pages</span>
					</span>
					<a-menu-item-group>
						<a-sub-menu key="profile" style="padding: 0;" title="Profile">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/pages/profile/profile-overview">
										<span class="label">Profile Overview</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/pages/profile/all-projects">
										<span class="label">All Projects</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
						<a-sub-menu key="users" style="padding: 0;" title="Users">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/pages/users/new-user">
										<span class="label">New User</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
						<a-sub-menu key="account" style="padding: 0;" title="Account">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/pages/account/settings">
										<span class="label">Settings</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/pages/account/billing">
										<span class="label">Billing</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/pages/account/invoice">
										<span class="label">Invoice</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
						<a-sub-menu key="projects" style="padding: 0;" title="Projects">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/pages/projects/timeline">
										<span class="label">Timeline</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
						<a-menu-item>
							<router-link to="/pages/pricing">
								<span class="label">Pricing</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/pages/rtl">
								<span class="label">RTL</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/pages/charts">
								<span class="label">Charts</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/pages/alerts">
								<span class="label">Alerts</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/pages/notifications">
								<span class="label">Notifications</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>
				<a-sub-menu key="applications" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="appstore" theme="filled" class="m-0" />
						</span>
						<span class="label">Applications</span>
					</span>
					<a-menu-item-group>
						<a-menu-item>
							<router-link to="/applications/kanban">
								<span class="label">Kanban</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/applications/wizard">
								<span class="label">Wizard</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/applications/datatables">
								<span class="label">DataTables</span>
							</router-link>
						</a-menu-item>
						<a-menu-item>
							<router-link to="/applications/calendar">
								<span class="label">Calendar</span>
							</router-link>
						</a-menu-item>
					</a-menu-item-group>
				</a-sub-menu>
				<a-sub-menu key="ecommerce" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="dollar-circle" theme="filled" class="m-0" />
						</span>
						<span class="label">Ecommerce</span>
					</span>
					<a-menu-item-group>
						<a-sub-menu key="products" style="padding: 0;" title="Products">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/ecommerce/products/new-product">
										<span class="label">New Product</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/ecommerce/products/edit-product">
										<span class="label">Edit Product</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/ecommerce/products/product-page">
										<span class="label">Product Page</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
						<a-sub-menu key="orders" style="padding: 0;" title="Orders">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/ecommerce/orders/orders-list">
										<span class="label">Order List</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/ecommerce/orders/orders-details">
										<span class="label">Order Details</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
					</a-menu-item-group>
				</a-sub-menu>
				<a-sub-menu key="authentication" style="padding: 0;">
					<span slot="title">
						<span class="icon">
							<a-icon type="lock" theme="filled" class="m-0" />
						</span>
						<span class="label">Authentication</span>
					</span>
					<a-menu-item-group>
						<a-sub-menu key="sign-up" style="padding: 0;" title="Sign Up">
							<a-menu-item-group>
								<a-menu-item>
									<router-link to="/authentication/sign-up/basic">
										<span class="label">Basic</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/authentication/sign-up/cover">
										<span class="label">Cover</span>
									</router-link>
								</a-menu-item>
								<a-menu-item>
									<router-link to="/authentication/sign-up/illustration">
										<span class="label">Illustration</span>
									</router-link>
								</a-menu-item>
							</a-menu-item-group>
						</a-sub-menu>
					</a-menu-item-group>
				</a-sub-menu>

				<a-menu-item class="menu-item-header">
					<hr class="mt-5">
					Docs
				</a-menu-item>
				<a-menu-item>
					<a href="https://demos.creative-tim.com/muse-vue-ant-design-dashboard-pro/documentation/overview" target="_blank">
						<span class="icon">
							<a-icon type="read" theme="filled" class="m-0" />
						</span>
						<span class="label">Getting Started</span>
					</a>
				</a-menu-item>
				<a-menu-item>
					<a href="https://demos.creative-tim.com/muse-vue-ant-design-dashboard-pro/documentation/components/alert" target="_blank">
						<span class="icon">
							<a-icon type="rocket" theme="filled" class="m-0" />
						</span>
						<span class="label">Components</span>
					</a>
				</a-menu-item>
				<a-menu-item>
					<a href="https://github.com/creativetimofficial/ct-muse-vue-ant-design-dashboard-pro/blob/main/CHANGELOG.md" target="_blank">
						<span class="icon">
							<a-icon type="switcher" theme="filled" class="m-0" />
						</span>
						<span class="label">Changelog</span>
					</a>
				</a-menu-item>
				-->
    </a-menu>
    <!-- / Sidebar Navigation Menu -->
  </a-layout-sider>
  <!-- / Main Sidebar -->
</template>

<script>
export default {
  props: {
    // Sidebar collapsed status.
    sidebarCollapsed: {
      type: Boolean,
      default: false,
    },

    // Main sidebar color.
    sidebarColor: {
      type: String,
      default: "primary",
    },

    // Main sidebar theme : light, white, dark.
    sidebarTheme: {
      type: String,
      default: "light",
    },
  },
  data() {
    return {
      rootSubmenuKeys: [
        "dashboards",
        "pages",
        "applications",
        "ecommerce",
        "authentication",
        "basic",
        "components",
        "changelog",
      ],
      openKeys: this.$route.meta.sidebarMap,
    };
  },
  methods: {
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );

      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
};
</script>
