<template>
    <div>
        <a-button @click="UpdateBalance()" class="ml-5">
            <a-icon type="reload" theme="outlined" />
                Refresh
        </a-button>
        <a-card :bordered="false" class="header-solid h-full" style="height: 160px;" :bodyStyle="{padding: 0}">
            <a-table :columns="columns" 
                     :data-source="balance_table" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true">
                     <!-- :loading="loading"> -->
                
            </a-table>
		</a-card>
    </div>
</template>
<script>
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
        exchange_name: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: 'Pair',
                    dataIndex: 'currency',
                },
                {
                    title: 'Free',
                    dataIndex: 'free',
                },
                {
                    title: 'Used',
                    dataIndex: 'used',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                },
                
                
            ],
            // amount: "1312800"
            // fee: "3,282"
            // fee_currency: "KRW"
            // order_balance: "0.00001006"
            // order_currency: "ETH"
            // payment_balance: "4445860"
            // payment_currency: "KRW"
            // price: "4000000"
            // search: "매도 완료"
            // transfer_date: "1649490057830038"
            // units: "0.3282"

            balance_table: [
            ],
            loading: true
        }
    },
    methods: {
        async UpdateBalance(){
            const res = await this.$http.post(`/api/v1/exchange2/${this.exchange_name}/balance`, {
                apikeyId: this.apikeyId,
                symbol: `${this.coin.toUpperCase()}_${this.pair.toUpperCase()}`,
                running_server: this.running_server
            })

            if (this.exchange_name === 'bithumb') {
                const balance_coin = res.data[this.coin.toUpperCase()]
                const balance_pair = res.data[this.pair.toUpperCase()]

                this.balance_table[0].currency = this.coin.toUpperCase()
                if (balance_coin !== undefined) {
                    this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                    this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                    this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
                } else {
                    this.balance_table[0].free = 0
                    this.balance_table[0].used = 0
                    this.balance_table[0].total = 0
                }
                
                this.balance_table[1].currency = this.pair.toUpperCase()
                if (balance_pair !== undefined) {
                    if (this.pair.toUpperCase() === 'KRW') {
                        this.balance_table[1].free = this.setPriceFormat(parseInt(balance_pair.free));
                        this.balance_table[1].used = this.setPriceFormat(parseInt(balance_pair.used));
                        this.balance_table[1].total = this.setPriceFormat(parseInt(balance_pair.total));
                    } else {
                        this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free));
                        this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used));
                        this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total));
                    }
                } else {
                    this.balance_table[1].free = 0
                    this.balance_table[1].used = 0
                    this.balance_table[1].total = 0
                }
            } else if (this.exchange_name === 'binance') {
                const balance_coin = res.data[this.coin.toUpperCase()]
                const balance_pair = res.data[this.pair.toUpperCase()]

                this.balance_table[0].currency = this.coin.toUpperCase()
                if (balance_coin !== undefined) {
                    this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                    this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                    this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
                } else {
                    this.balance_table[0].free = 0
                    this.balance_table[0].used = 0
                    this.balance_table[0].total = 0
                }
                
                this.balance_table[1].currency = this.pair.toUpperCase()
                if (balance_pair !== undefined) {
                    this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free))
                    this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used))
                    this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
                } else {
                    this.balance_table[1].free = 0
                    this.balance_table[1].used = 0
                    this.balance_table[1].total = 0
                }
            } else if (this.exchange_name === 'bitget') {
                const balance_array = res.data.data;
                let balance = {};
                balance_array.forEach((s) => {
                    const currency = s.coinName.toUpperCase();
                    if (!balance[currency]) {
                        balance[currency] = { free: 0, used: 0, total: 0 };
                    }
                    balance[currency].free += parseFloat(s.available);
                    balance[currency].used += parseFloat(s.frozen);
                    balance[currency].total = balance[currency].free + balance[currency].used;
                });

                let balance_coin = balance[this.coin.toUpperCase()]
                let balance_pair = balance[this.pair.toUpperCase()]

                this.balance_table[0].currency = this.coin
                if (balance_coin !== undefined) {
                    this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                    this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                    this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
                } else {
                    this.balance_table[0].free = 0
                    this.balance_table[0].used = 0
                    this.balance_table[0].total = 0
                }
                
                this.balance_table[1].currency = this.pair
                if (balance_pair !== undefined) {
                    this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free))
                    this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used))
                    this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
                } else {
                    this.balance_table[1].free = 0
                    this.balance_table[1].used = 0
                    this.balance_table[1].total = 0
                }
            } else if (this.exchange_name === 'lbank') {
                const balance_coin = res.data[this.coin.toLowerCase()]
                const balance_pair = res.data[this.pair.toLowerCase()]

                this.balance_table[0].currency = this.coin.toUpperCase()
                if (balance_coin !== undefined) {
                    this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                    this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                    this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
                } else {
                    this.balance_table[0].free = 0
                    this.balance_table[0].used = 0
                    this.balance_table[0].total = 0
                }
                
                this.balance_table[1].currency = this.pair.toUpperCase()
                if (balance_pair !== undefined) {
                    this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free))
                    this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used))
                    this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
                } else {
                    this.balance_table[1].free = 0
                    this.balance_table[1].used = 0
                    this.balance_table[1].total = 0
                }
            } else if (this.exchange_name === 'coinbase') {
                const balance_coin = res.data[this.coin.toUpperCase()]
                const balance_pair = res.data[this.pair.toUpperCase()]

                this.balance_table[0].currency = this.coin
                if (balance_coin !== undefined) {
                    this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                    this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                    this.balance_table[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
                } else {
                    this.balance_table[0].free = 0
                    this.balance_table[0].used = 0
                    this.balance_table[0].total = 0
                }
                
                this.balance_table[1].currency = this.pair
                if (balance_pair !== undefined) {
                    this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free))
                    this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used))
                    this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
                } else {
                    this.balance_table[1].free = 0
                    this.balance_table[1].used = 0
                    this.balance_table[1].total = 0
                }
            } else if (this.exchange_name === 'mexc') {
                const balance_coin = res.data[this.coin.toUpperCase()]
                const balance_pair = res.data[this.pair.toUpperCase()]

                this.balance_table[0].currency = this.coin.toUpperCase()
                if (balance_coin !== undefined) {
                    this.balance_table[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                    this.balance_table[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                    this.balance_table[0].total =this.setPriceFormat(parseFloat(balance_coin.total))
                } else {
                    this.balance_table[0].free = 0
                    this.balance_table[0].used = 0
                    this.balance_table[0].total = 0
                }
                
                this.balance_table[1].currency = this.pair.toUpperCase()
                if (balance_pair !== undefined) {
                    this.balance_table[1].free = this.setPriceFormat(parseFloat(balance_pair.free))
                    this.balance_table[1].used = this.setPriceFormat(parseFloat(balance_pair.used))
                    this.balance_table[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
                } else {
                    this.balance_table[1].free = 0
                    this.balance_table[1].used = 0
                    this.balance_table[1].total = 0
                }
            }

            
        },
        init_balance(){
            this.balance_table = []
            this.balance_table.push({
                currency: this.coin,
                free: 0,
                used: 0,
                total: 0
            })
            this.balance_table.push({
                currency: this.pair,
                free: 0,
                used: 0,
                total: 0
            })
        },
        setPriceFormat(v){
            return Number(v).toLocaleString(undefined, { maximumFractionDigits: 5 })
        }
        
    },
    async mounted() {
        this.init_balance()
        await this.UpdateBalance()
        // this.loading = false
        this.updateInterval = setInterval(() => {
            this.UpdateBalance()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        pair(v){
            // this.loading = true
            this.UpdateBalance()
            // this.loading = false
        }
    },

}
</script>

<style lang="scss">

</style>