<template>
  <a-form-model ref="ruleForm" :model="updateData" layout="vertical">
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Name">
          <a-input v-model="updateData.name" />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="Running Server">
          <a-select v-model="updateData.running_server">
            <a-select-option v-for="v in server_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item label="Exchange">
          <a-select v-model="updateData.exchange">
            <a-select-option v-for="(e, i) in exchange" :value="e.value" :key="i">{{ e.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="Currencies">
          <a-select v-model="updateData.currencies" :loading="is_currency_loading" mode="multiple">
            <a-select-option v-for="(e, i) in currency_list" :value="e.toLowerCase()" :key="i">{{e}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Apikey">
          <a-input
            v-model="edit_api_key"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item
          class="mb-20"
          label="Secret"
        >
          <a-input
            v-model="edit_secret_key"
          />
        </a-form-model-item>
      </a-col>
    </a-row>
    
    <template>
      <a-row :gutter="16">
          <a-col>
              <a-button @click="toggleImage">{{ isImageVisible ? '접기' : 'API 입력 가이드' }}</a-button>
              <transition name="fade">
                  <img v-if="isImageVisible" src="./API_rule.png" alt="api_rule" class="responsive-image">
              </transition>
          </a-col>
      </a-row>
   </template>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        :style="{ marginRight: '5px' }"
        type="primary"
        size="small"
        html-type="submit"
        @click="onSubmit"
      >
        MODIFY
      </a-button>
      <a-button class="px-15" type="danger" size="small" @click="onDelete">
        DELETE
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
export default {
  props: ["updateData"],
  data() {
    return {
      exchange: [
        
      ],
      currency_list: [

      ],
      server_list: [
        {
            label: 'Master',
            value: 'master',
        },
        {
            label: 'LP1',
            value: 'lp1',
        },
        {
            label: 'LP2',
            value: 'lp2',
        },
        {
            label: 'LP3',
            value: 'lp3',
        },
        {
            label: 'LP4',
            value: 'lp4',
        },
        {
            label: 'LP5',
            value: 'lp5',
        },
        {
            label: 'LP6',
            value: 'lp6',
        },
        {
            label: 'Dev1',
            value: 'lp7',
        },
        {
            label: 'Dev2',
            value: 'lp8',
        },
      ],
      is_currency_loading: true,
      edit_api_key: '',
      edit_secret_key: '',
      isImageVisible: false
      // rules: {
      //   password: [
      //     {
      //       required: true,
      //       message: "Please enter Password",
      //     },
      //   ],
      //   repeatpassword: [
      //     {
      //       required: true,
      //       message: "Please enter Repeat Password",
      //     },
      //   ],
      // },
    };
  },
  computed: {
  },
  watch: {
    async updateData(n) {
      await this.get_symbols(n.exchange);
    },
  },
  async mounted() {
    await this.getExchange()
    if (this.updateData.exchange){
      await this.get_symbols(this.updateData.exchange);
    }
  },
  methods: {
    toggleImage() {
        this.isImageVisible = !this.isImageVisible;
    },
    async get_symbols(e){
      // console.log(e)
      this.is_currency_loading = true
      const runserverValue = this.updateData.running_server;
      const res = await this.$http.get(`/api/v1/exchange2/${e}/symbols/${runserverValue}`)
      this.currency_list = res.data
      this.is_currency_loading = false
      // console.log(this.currency_list)
    },
    async getExchange() {
        try {
            const res = await this.$http.get('/api/v1/info/exchange')
            for (const [key, value] of Object.entries(res.data)) {
                this.exchange.push({
                    label: key,
                    value
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    get_edit_data(){
      const data = {
        id: this.updateData.id,
        name: this.updateData.name,
        exchange: this.updateData.exchange.toLowerCase(),
        running_server: this.updateData.running_server,
        currencies: Array.from(this.updateData.currencies)
      }
      if(this.edit_api_key){
        data.api_key = this.edit_api_key
      }
      if(this.edit_secret_key){
        data.secret_key = this.edit_secret_key
      }
      return data
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log("Received values of form: ", this.user);
          this.$http
            .put(`/api/v1/apikeys/${this.updateData.id}`, this.get_edit_data())
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    onDelete() {
      this.$http
        .delete(`/api/v1/apikeys/${this.updateData.id}`, {
          // params: {
          //   id: this.updateData.id,
          // },
        })
        .then(() => {
          this.redirect();
        });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go(0);
      // this.$router.push(`/api/v1/apikeys`);
    },
  },
};
</script>

<style>
.responsive-image {
    max-width: 100%;
    height: auto;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
