<template>
    <div>
      <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
        <a-table :columns="columns"
                 :data-source="orderbook"
                 :pagination="false"
                 :showHeader="true"
                 :scroll="{ y: 1200 }">
            <template slot="quantity-column" slot-scope="text, record">
                <div class="quantity-container">
                  <span @click="quantityClickHandler(text)">
                      {{ Number(text).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                  </span>
                  <span v-if="showMarkers" class="my-quantity-label">
                    <!-- ask_q에만 ask_my_q 표시 -->
                    <template v-if="record.ask_my_p && text === record.ask_q">
                        ({{ Number(record.ask_my_q).toLocaleString(undefined, {maximumFractionDigits: 8}) }})
                    </template>
                    <!-- bid_q에만 bid_my_q 표시 -->
                    <template v-if="record.bid_my_p && text === record.bid_q">
                        ({{ Number(record.bid_my_q).toLocaleString(undefined, {maximumFractionDigits: 8}) }})
                    </template>
                    <!-- ask_cum_q에만 ask_my_cum_q 표시 -->
                    <template v-if="record.ask_my_p && text === record.ask_cum_q">
                        ({{ Number(record.ask_my_cum_q).toLocaleString(undefined, {maximumFractionDigits: 8}) }})
                    </template>
                    <!-- bid_cum_q에만 bid_my_cum_q 표시 -->
                    <template v-if="record.bid_my_p && text === record.bid_cum_q">
                        ({{ Number(record.bid_my_cum_q).toLocaleString(undefined, {maximumFractionDigits: 8}) }})
                    </template>
                    <!-- ask_cum_p에만 ask_my_cum_p 표시 -->
                    <template v-if="record.ask_my_p && text === record.ask_cum_p">
                        ({{ Number(record.ask_my_cum_p).toLocaleString(undefined, {maximumFractionDigits: 8}) }})
                    </template>
                    <!-- bid_cum_p에만 bid_my_cum_p 표시 -->
                    <template v-if="record.bid_my_p && text === record.bid_cum_p">
                        ({{ Number(record.bid_my_cum_p).toLocaleString(undefined, {maximumFractionDigits: 8}) }})
                    </template>
                  </span>
                </div>
            </template>
            <template slot="price-column" slot-scope="text">
                <span @click="priceClickHandler(text)">
                    {{ Number(text).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
                </span>
                <span v-if="showMarkers && shouldShowMarker(text)" class="price-marker"></span>
            </template>

            <template slot="comma-format" slot-scope="text">
                {{ Number(text).toLocaleString(undefined, { maximumFractionDigits: 8 }) }}
            </template>

            <template slot="left-click-order" slot-scope="text, record">
                <a-button v-if="selectClickOrderType == 'bid'" type="primary" ghost class="m-0" @click="clickOrderBidButtonHandler(record.bid_p)">Buy</a-button>
                <a-button v-else danger class="m-0" @click="clickOrderAskButtonHandler(record.bid_p)">Sell</a-button>
            </template>
            <template slot="right-click-order" slot-scope="text, record">
                <a-button v-if="selectClickOrderType == 'bid'" type="primary" ghost class="m-0" @click="clickOrderBidButtonHandler(record.ask_p)">Buy</a-button>
                <a-button v-else danger class="m-0" @click="clickOrderAskButtonHandler(record.ask_p)">Sell</a-button>
            </template>
        </a-table>
      </a-card>
    </div>
</template>
<script>
const ExchangeName = "xt";

export default {
    props: {
        pair: String,
        coin: String,
        selectClickOrderType: String,
        selectPriceUnit: Number,
        selectCumViewType: String,
        running_server: String,
        showMarkers: { // showMarkers를 props로 수신
            type: Boolean,
            default: true,
        },
        apikeyId: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: "주문",
                    class: "py-0",
                    width: "90px",
                    scopedSlots: { customRender: "left-click-order" },
                },
                {
                    title: "누적매수가",
                    dataIndex: "bid_cum_p",
                    align: "right",
                    class: "cum-cell left-line right-line",
                    scopedSlots: { customRender: 'quantity-column'}
                },
                {
                    title: "누적수량",
                    dataIndex: "bid_cum_q",
                    align: "right",
                    class: "cum-cell right-line",
                    scopedSlots: { customRender: "quantity-column" },
                },
                {
                    title: "수량",
                    dataIndex: "bid_q",
                    align: "right",
                    scopedSlots: { customRender: "quantity-column" },
                },
                {
                    title: "매수가",
                    dataIndex: "bid_p",
                    class: "bid-price font-bold",
                    scopedSlots: { customRender: "price-column" },
                },
                {
                    title: "매도가",
                    dataIndex: "ask_p",
                    class: "ask-price font-bold",
                    scopedSlots: { customRender: "price-column" },
                },
                {
                    title: "수량",
                    dataIndex: "ask_q",
                    align: "right",
                    scopedSlots: { customRender: "quantity-column" },
                },
                {
                    title: "누적수량",
                    dataIndex: "ask_cum_q",
                    align: "right",
                    class: "cum-cell left-line right-line",
                    scopedSlots: { customRender: "quantity-column" },
                },
                {
                    title: "누적매도가",
                    dataIndex: "ask_cum_p",
                    align: "right",
                    class: "cum-cell right-line",
                    scopedSlots: { customRender: 'quantity-column'}
                },
                {
                    title: "주문",
                    class: "py-0",
                    width: "90px",
                    scopedSlots: { customRender: "right-click-order" },
                },
            ],

            orderbook_update_timestamp: null,
            orderbook: [],
            my_order: []
        }
    },
  methods: {
    getSymbol() {
      return `${this.coin}_${this.pair}`;
    },
    shouldShowMarker(price) {
        // orderbook 데이터를 참조하여 마커 상태 확인
        return this.orderbook.some(entry =>
            (Number(entry.ask_p) === Number(price) && entry.ask_my_p) || // Ask 마커 조건
            (Number(entry.bid_p) === Number(price) && entry.bid_my_p)   // Bid 마커 조건
        );
    },
    async UpdateMyOrder(){
      if (this.showMarkers) {
        const my_order_ = await this.$http.post(`/api/v1/exchange2/${ExchangeName}/openorders`, {
            apikeyId: this.apikeyId,
            symbol: `${this.coin}_${this.pair}`,
            running_server: this.running_server
        })
        this.my_order = my_order_.data;
      }
    },
    async UpdateOrderbook() {
      const { data: res_data } = await this.$http.get(`/api/v1/exchange2/${ExchangeName}/orderbook/${this.running_server}/${this.getSymbol()}`);
      const orderbook = [];
      const limit = Math.max(res_data.asks.length, res_data.bids.length);
      for (let i = 0; i < limit; i++) {
        orderbook.push({
          index: i,
          ask_p: 0,
          ask_q: 0,
          bid_p: 0,
          bid_q: 0,
          ask_cum_q: 0,
          ask_cum_p: 0,
          bid_cum_q: 0,
          bid_cum_p: 0,
          ask_my_p: false,
          ask_my_q: 0,
          ask_my_cum_q: 0,
          ask_my_cum_p: 0,
          bid_my_p: false,
          bid_my_q: 0,
          bid_my_cum_q: 0,
          bid_my_cum_p: 0
        });
      }
      res_data.asks.forEach((data, index) => {
        const data_0 = parseFloat(data[0]);
        const data_1 = parseFloat(data[1]);
        orderbook[index].ask_p = data[0];
        orderbook[index].ask_q = data[1];
        if (index === 0) {
          orderbook[index].ask_cum_q = data_1;
          orderbook[index].ask_cum_p = data_1 * data_0;
        } else {
          orderbook[index].ask_cum_q =
            parseFloat(orderbook[index - 1].ask_cum_q) + data_1;
          orderbook[index].ask_cum_p =
            parseFloat(orderbook[index - 1].ask_cum_p) + data_1 * data_0;
        }
      });
      res_data.bids.forEach((data, index) => {
        const data_0 = parseFloat(data[0]);
        const data_1 = parseFloat(data[1]);
        orderbook[index].bid_p = data[0];
        orderbook[index].bid_q = data[1];
        if (index === 0) {
          orderbook[index].bid_cum_q = data_1;
          orderbook[index].bid_cum_p = data_1 * data_0;
        } else {
          orderbook[index].bid_cum_q =
            parseFloat(orderbook[index - 1].bid_cum_q) + data_1;
          orderbook[index].bid_cum_p =
            parseFloat(orderbook[index - 1].bid_cum_p) + data_1 * data_0;
        }
      });


      if (this.my_order && Array.isArray(this.my_order)) {
          this.my_order.forEach((order) => {
              const price = parseFloat(order.price);             // My Order의 price
              const unitsRemaining = parseFloat(order.leavingQty); // My Order의 units_remaining

              // ask_p 값 비교 및 업데이트
              orderbook.forEach((entry, index) => {
                  if (parseFloat(entry.ask_p) === price) {
                      entry.ask_my_p = true;
                      entry.ask_my_q += unitsRemaining;
                  }

                  // bid_p 값 비교 및 업데이트
                  if (parseFloat(entry.bid_p) === price) {
                      entry.bid_my_p = true;
                      entry.bid_my_q += unitsRemaining;
                  }

                  // 누적 값 계산 추가
                  if (index === 0) {
                      entry.ask_my_cum_q = entry.ask_my_q;
                      entry.bid_my_cum_q = entry.bid_my_q;
                      entry.ask_my_cum_p = entry.ask_my_q * entry.ask_p;
                      entry.bid_my_cum_p = entry.bid_my_q * entry.bid_p;
                  } else {
                      entry.ask_my_cum_q = orderbook[index - 1].ask_my_cum_q + entry.ask_my_q;
                      entry.bid_my_cum_q = orderbook[index - 1].bid_my_cum_q + entry.bid_my_q;
                      entry.ask_my_cum_p = orderbook[index - 1].ask_my_cum_p + (entry.ask_my_q * entry.ask_p);
                      entry.bid_my_cum_p = orderbook[index - 1].bid_my_cum_p + (entry.bid_my_q * entry.bid_p);
                  }
              });
          });
      }
      this.orderbook = orderbook;
    },
    init_orderbook() {
      const limit = 30;
      this.orderbook = [];
      for (let i = 0; i < limit; i++) {
        this.orderbook.push({
          index: i,
          ask_p: 0,
          ask_q: 0,
          bid_p: 0,
          bid_q: 0,
          ask_cum_q: 0,
          ask_cum_p: 0,
          bid_cum_q: 0,
          bid_cum_p: 0,
          ask_my_p: false,
          ask_my_q: 0,
          ask_my_cum_q: 0,
          ask_my_cum_p: 0,
          bid_my_p: false,
          bid_my_q: 0,
          bid_my_cum_q: 0,
          bid_my_cum_p: 0
        });
      }
      // this.orderbook_update_timestamp = null
    },
    quantityClickHandler(q) {
      this.$emit("setQuantityForm", q);
    },
    priceClickHandler(p) {
      this.$emit("setPriceForm", p);
    },
    // clickOrderButtonHandler(record, type){
    //     if(type === 'buy'){
    //         this.$emit('clickOrderAsk', record.ask_p)
    //     } else if(type === 'sell'){

    //     }
    // },
    clickOrderAskButtonHandler(ask_price) {
      this.$emit("clickOrderAsk", ask_price);
    },
    clickOrderBidButtonHandler(bid_price) {
      this.$emit("clickOrderBid", bid_price);
    },
  },
  async mounted() {
      this.init_orderbook()
      await this.UpdateOrderbook()
      await this.UpdateMyOrder()
      this.updateInterval = setInterval(() => {
          this.UpdateOrderbook();
      }, (1000))
      this.updateInterval2 = setInterval(() => {
        if (this.showMarkers) {
            this.UpdateMyOrder();
        }
      }, (5000))
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
    clearInterval(this.updateInterval2)
  },
};
</script>

<style lang="scss">
td.ask-price {
  background-color: #eef6ff !important;
}
td.bid-price {
  background-color: #fff0ef !important;
}
td.cum-cell {
  // border-left: 1px solid #e8e8e8;
  background-color: #f1f1f1 !important;
}
td.left-line {
  border-left: 1px solid black;
}
td.right-line {
  border-right: 1px solid black;
}

.price-container {
    display: flex;
    align-items: center; /* 텍스트와 마커 정렬 */
}

.price-marker {
    display: inline-block;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    background-color: red; /* 마커 색상 */
    border-radius: 50%; /* 원형 */
}

.quantity-container {
    display: flex;
    flex-direction: column; /* 수량과 (my quantity)를 세로로 배치 */
    align-items: flex-start; /* 왼쪽 정렬 */
    line-height: 1.2;
}

.my-quantity-label {
    font-size: 12px;
    color: gray; /* (my quantity) 텍스트 색상 */
    margin-top: 2px; /* 수량과 텍스트 간격 */
}
</style>