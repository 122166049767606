import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/exchange/bithumb",
  },
  {
    path: "/auth/signin",
    name: "Sign In",
    meta: {
      layoutClass: "layout-sign-up",
      title: "Sign In",
      unauthorized: true,
      // sidebarMap: ['authentication', 'sign-up', 'basic'],
      // breadcrumbs: ['Authentication', 'Sign Up', 'Basic'],
    },
    component: () => import("../views/Authentication/Sign-In/Basic.vue"),
  },
  {
    path: "/exchange/bithumb",
    name: "Bithumb",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Bithumb"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Bithumb/trade.vue"
      ),
  },
  {
    path: "/exchange/bitkub",
    name: "Bitkub",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Bitkub"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Bitkub/trade.vue"
      ),
  },
  {
    path: "/exchange/coinone",
    name: "Coinone",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Coinone"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Coinone/trade.vue"
      ),
  },
  {
    path: "/exchange/xt",
    name: "XT",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "XT"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Xt/trade.vue"
      ),
  },
  {
    path: "/exchange/poloniex",
    name: "Poloniex",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Poloniex"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Poloniex/trade.vue"
      ),
  },
  {
    path: "/exchange/binance",
    name: "Binance",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Binance"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Binance/trade.vue"
      ),
  },
  // {
  //   path: "/exchange/binancefuture",
  //   name: "Binance",
  //   layout: "dashboard",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   meta: {
  //     title: "Exchange",
  //     sidebarMap: ["exchanges"],
  //     breadcrumbs: ["Exchange", "Binance"],
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "dashboard" */ "../views/Exchange/BinanceFuture/trade.vue"
  //     ),
  // },
  {
    path: "/exchange/gopax",
    name: "Gopax",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Gopax"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Gopax/trade.vue"
      ),
  },
  {
    path: "/exchange/lbank",
    name: "LBank",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Lbank"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/LBank/trade.vue"
      ),
  },
  {
    path: "/exchange/huobi",
    name: "Huobi",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Huobi"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Huobi/trade.vue"
      ),
  },
  {
    path: "/exchange/gateio",
    name: "Gateio",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Gateio"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Gateio/trade.vue"
      ),
  },
  {
    path: "/exchange/mexc",
    name: "Mexc",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Mexc"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Mexc/trade.vue"
      ),
  },
  {
    path: "/exchange/okex",
    name: "Okex",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Okex"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Okex/trade.vue"
      ),
  },
  {
    path: "/exchange/digifinex",
    name: "Digifinex",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "Digifinex"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Digifinex/trade.vue"
      ),
  },
  {
    path: "/exchange/bitmart",
    name: "Bitmart",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "bitmart"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Bitmart/trade.vue"
      ),
  },
  {
    path: "/exchange/whitebit",
    name: "Whitebit",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "whitebit"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Whitebit/trade.vue"
      ),
  },
  {
    path: "/exchange/bitget",
    name: "Bitget",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "bitget"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Bitget/trade.vue"
      ),
  },
  {
    path: "/exchange/coinstore",
    name: "Coinstore",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "coinstore"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Coinstore/trade.vue"
      ),
  },
  {
    path: "/exchange/upbit",
    name: "Upbit",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "upbit"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Upbit/trade.vue"
      ),
  },
  {
    path: "/exchange/kucoin",
    name: "Kucoin",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "kucoin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Kucoin/trade.vue"
      ),
  },
  {
    path: "/exchange/coinbase",
    name: "Coinbase",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "coinbase"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Coinbase/trade.vue"
      ),
  },
  {
    path: "/exchange/bitflyer",
    name: "Bitflyer",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "bitflyer"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Bitflyer/trade.vue"
      ),
  },
  {
    path: "/exchange/probit",
    name: "Probit",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Exchange",
      sidebarMap: ["exchanges"],
      breadcrumbs: ["Exchange", "probit"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Probit/trade.vue"
      ),
  },
  // {
  //   path: "/exchange/gemini",
  //   name: "Gemini",
  //   layout: "dashboard",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   meta: {
  //     title: "Exchange",
  //     sidebarMap: ["exchanges"],
  //     breadcrumbs: ["Exchange", "gemini"],
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "dashboard" */ "../views/Exchange/Gemini/trade.vue"
  //     ),
  // },
  {
    path: "/autotrade/countingbot",
    name: "CountingBot",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "CountingBot",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "CountingBot"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/CountingBot/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/crosstrade",
    name: "CrossTrade",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "CrossTrade",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "CrossTrade"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/CrossTrade/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/crosstrade_v2",
    name: "CrossTradeV2",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "CrossTrade2",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "CrossTradeV2"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/CrossTradeV2/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/crosstrade_v2_1",
    name: "CrossTradeV2-1",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "CrossTrade2-1",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "CrossTradeV2_1"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/CrossTradeV2_1/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/multi_crosstrade",
    name: "multiCrossTrade",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "MultiCrossTrade",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "multiCrossTrade"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/multiCrossTrade/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/central_control",
    name: "centralControl",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "CentralControl",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "centralControl"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/centralControl/dashboard.vue"
      ),
  },
  // {
	// 	path: '/autotrade/arbitrage',
	// 	name: 'Arbitrage',
	// 	layout: "dashboard",
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	meta: {
	// 		title: 'Arbitrage',
	// 		sidebarMap: ['arbitrage'],
	// 		breadcrumbs: ['Autotrade', 'Arbitrage'],
	// 	},
	// 	component: () => import(/* webpackChunkName: "dashboard" */ '../views/Arbitrage/dashboard.vue'),
	// },
  {
    path: "/autotrade/fillblank",
    name: "FillBlank",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "FillBlank",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "FillBlank"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/FillBlank/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/fillblank_v2",
    name: "FillBlankV2",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "FillBlankV2",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "FillBlankV2"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/FillBlankV2/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/fillblank_v3",
    name: "FillBlankV3",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "FillBlankV3",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "FillBlankV3"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/FillBlankV3/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/fillblank_central",
    name: "FillBlankCentral",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "FillBlankCentral",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "FillBlankCentral"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/FillBlankCentral/dashboard.vue"
      ),
  },
  {
    path: "/autotrade/fillblankfakewall",
    name: "FakeWall",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "FakeWall",
      sidebarMap: ["autotrade"],
      breadcrumbs: ["Autotrade", "FakeWall"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/FillBlankFakeWall/dashboard.vue"
      ),
  },
  {
    path: "/scheduler/v1/dashboard",
    name: "Scheduler",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Scheduler",
      sidebarMap: ["scheduler"],
      breadcrumbs: ["scheduler", "Dashboard"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/Scheduler/dashboard.vue"
      ),
  },
  // {
  //   path: "/scheduler/v2/dashboard",
  //   name: "SchedulerV2",
  //   layout: "dashboard",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   meta: {
  //     title: "SchedulerV2",
  //     sidebarMap: ["schedulerV2"],
  //     breadcrumbs: ["schedulerV2", "Dashboard"],
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "dashboard" */ "../views/Exchange/SchedulerV2/dashboard.vue"
  //     ),
  // },
  {
    path: "/mm/dashboard",
    name: "MM",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "MM",
      sidebarMap: ["MM"],
      breadcrumbs: ["MM", "Dashboard"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/MM/dashboard.vue"
      ),
  },
  {
    path: "/assetmovement/deposit",
    name: "Deposit",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Deposit",
      sidebarMap: ["asset_movement"],
      breadcrumbs: ["Transfer", "Deposit"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Assetmovement/Deposit/dashboard.vue"
      ),
  },
  {
    path: "/assetmovement/withdraw",
    name: "Withdraw",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Withdraw",
      sidebarMap: ["asset_movement"],
      breadcrumbs: ["Transfer", "Withdraw"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Assetmovement/Withdraw/dashboard.vue"
      ),
  },
  {
    path: "/assetmovement/address",
    name: "Address",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Address",
      sidebarMap: ["asset_movement"],
      breadcrumbs: ["Transfer", "Address"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Assetmovement/Address/dashboard.vue"
      ),
  },
  {
    path: "/pricenotification/list",
    name: "PriceNotification",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Notification",
      sidebarMap: ["PriceNotification"],
      breadcrumbs: ["PriceNotification", "Dashboard"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/Exchange/PriceNotification/List.vue"
      ),
  },
  {
    path: "/settings/users",
    name: "UserSettting",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Users",
      sidebarMap: ["settings"],
      breadcrumbs: ["Settings", "Users"],
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Users/UserList.vue"),
  },
  {
    path: "/settings/apikey",
    name: "APIKeySettting",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "APIKeys",
      sidebarMap: ["settings"],
      breadcrumbs: ["Settings", "APIKey"],
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Apikeys/List.vue"),
  },
  {
    path: "/settings/telegram",
    name: "TelegramSettting",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "Telegram",
      sidebarMap: ["telegram"],
      breadcrumbs: ["Settings", "Telegram"],
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Telegram/List.vue"),
  },
  {
    path: "/settings/telegrambot",
    name: "TelegramSettting",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    meta: {
      title: "TelegramBot",
      sidebarMap: ["telegrambot"],
      breadcrumbs: ["Settings", "TelegramBot"],
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../views/TelegramBot/List.vue"
      ),
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log(process.env.BASE_URL);
    // console.log(to.hash);
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

// 원본
router.beforeEach(async (to, from, next) => {
  if (
    localStorage.getItem("accessToken") === null &&
    localStorage.getItem("refreshToken") !== null
  ) {
    await store.dispatch("refresh");
  }
  if (
    to.matched.some((record) => record.meta.unauthorized) ||
    localStorage.getItem("accessToken")
  ) {
    return next();
  }
  return next("/auth/signin");
});

export default router;
