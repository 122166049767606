<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			<a-table :columns="columns" 
                     :data-source="balance_table2" 
                     :row-key="record => record.transfer_date"
                     :pagination="false" 
                     :showHeader="true"
                     :loading="loading">
                
            </a-table>
		</a-card>
    </div>
</template>
<script>
const Exchange = 'mexc'
export default {
    props: {
        coin: String,
        pair: String,
        apikeyId: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: 'Pair',
                    dataIndex: 'currency',
                },
                {
                    title: 'Free',
                    dataIndex: 'free',
                },
                {
                    title: 'Used',
                    dataIndex: 'used',
                },
                {
                    title: 'Total',
                    dataIndex: 'total',
                },
                
                
            ],
            // amount: "1312800"
            // fee: "3,282"
            // fee_currency: "KRW"
            // order_balance: "0.00001006"
            // order_currency: "ETH"
            // payment_balance: "4445860"
            // payment_currency: "KRW"
            // price: "4000000"
            // search: "매도 완료"
            // transfer_date: "1649490057830038"
            // units: "0.3282"

            balance_table2: [
            ],
            loading: true
        }
    },
    methods: {
        async UpdateBalance2(){
            const res = await this.$http.post(`/api/v1/exchange2/${Exchange}/balance`, {
                apikeyId: this.apikeyId,
                running_server: this.running_server
            })

            this.balance_table2 = [
                {
                    currency: this.coin.toUpperCase(),
                    free: 0,
                    used: 0,
                    total: 0
                },
                {
                    currency: this.pair.toUpperCase(),
                    free: 0,
                    used: 0,
                    total: 0
                }
            ];

            const balance_coin = res.data[this.coin.toUpperCase()];
            const balance_pair = res.data[this.pair.toUpperCase()];

            if (balance_coin !== undefined) {
                this.balance_table2[0].free = this.setPriceFormat(parseFloat(balance_coin.free))
                this.balance_table2[0].used = this.setPriceFormat(parseFloat(balance_coin.used))
                this.balance_table2[0].total = this.setPriceFormat(parseFloat(balance_coin.total))
            } 
            
            if (balance_pair !== undefined) {
                this.balance_table2[1].free = this.setPriceFormat(parseFloat(balance_pair.free))
                this.balance_table2[1].used = this.setPriceFormat(parseFloat(balance_pair.used))
                this.balance_table2[1].total = this.setPriceFormat(parseFloat(balance_pair.total))
            }
            
            for (const [assetName, asset] of Object.entries(res.data)) {
                if (assetName !== this.coin.toUpperCase() && assetName !== this.pair.toUpperCase() && (parseFloat(asset.free) > 0 || parseFloat(asset.used) > 0)) {
                    this.balance_table2.push({
                        currency: assetName,
                        free: this.setPriceFormat(parseFloat(asset.free)),
                        used: this.setPriceFormat(parseFloat(asset.used)),
                        total: this.setPriceFormat(parseFloat(asset.total))
                    });
                }
            }
        },
        init_balance2(){
            this.balance_table2 = []
            this.balance_table2.push({
                currency: this.coin.toUpperCase(),
                free: 0,
                used: 0,
                total: 0
            })
            this.balance_table2.push({
                currency: this.pair.toUpperCase(),
                free: 0,
                used: 0,
                total: 0
            })
        },
        setPriceFormat(v){
            return Number(v).toLocaleString(undefined, { maximumFractionDigits: 5 })
        }
        
    },
    async mounted() {
        this.init_balance2()
        await this.UpdateBalance2()
        this.loading = false
        this.updateInterval = setInterval(() => {
            this.UpdateBalance2()
        
        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
    watch: {
        pair(v){
            this.loading = true
            this.UpdateBalance2()
            this.loading = false
        }
    },

}
</script>

<style lang="scss">

</style>