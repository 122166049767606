<template>
  <a-form
    :form="form"
    layout="vertical"
    hide-required-mark
    @submit="handleSubmit"
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please enter Address name' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Method">
          <a-select
            placeholder="" 
            @change="changeMethod"
          >
              <a-select-option v-for="v in method_list" :key="v.value" :value="v.value">
                  {{ v.label }}
              </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <template v-if="input_method==='manual'">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item class="mb-20" label="Exchange">
            <a-input
              v-decorator="[
                'exchange',
                {
                  rules: [{ required: true, message: 'Please enter Exchange name' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item class="mb-20" label="Token">
            <a-input
              v-decorator="[
                'token',
                {
                  rules: [{ required: true, message: 'Please enter Token name' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>

      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item class="mb-20" label="Network">
            <a-input
              v-decorator="[
                'network',
                {
                  rules: [{ required: true, message: 'Please enter Network name' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item class="mb-20" label="Address">
            <a-input
              v-decorator="[
                'address',
                {
                  rules: [{ required: true, message: 'Please enter Address' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
      </a-row>
      
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item class="mb-20" label="Description">
            <a-input
              v-decorator="[
                'description',
                {
                  rules: [{ required: true, message: 'Please enter Description' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
      </a-row>
    </template>

    <template v-if="input_method==='select'">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item class="mb-10" label="Running Server" :colon="false">
            <a-select v-decorator="[
                'runserver',
                {
                  rules: [{ required: true, message: 'Please select server' }],
                },
              ]"
              placeholder="">
              <a-select-option v-for="v in server_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item class="mb-10" label="Exchange" :colon="false">
            <a-select v-decorator="[
                'exchange',
                {
                  rules: [{ required: true, message: 'Please select exchange' }],
                },
              ]"
              placeholder="" @change="get_symbols">
              <a-select-option v-for="(e, i) in exchange" :value="e.value" :key="i">{{ e.label }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item class="mb-10" label="Token" :colon="false">
            <a-select v-decorator="[
                'token',
                {
                  rules: [{ required: true, message: 'Please select Token' }],
                },
              ]"
              placeholder="" :loading="is_currency_loading" show-search>
              <a-select-option v-for="(e, i) in currency_list" :value="e.toLowerCase()" :key="i">{{e}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item class="mb-20" label="Network">
            <a-input
              v-decorator="[
                'network',
                {
                  rules: [{ required: true, message: 'Please enter Network name' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item class="mb-20" label="Address">
            <a-input
              v-decorator="[
                'address',
                {
                  rules: [{ required: true, message: 'Please enter Address' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item class="mb-20" label="Description">
            <a-input
              v-decorator="[
                'description',
                {
                  rules: [{ required: true, message: 'Please enter Description' }],
                },
              ]"
              placeholder=""
            />
          </a-form-item>
        </a-col>
      </a-row>

    </template>

    <template v-if="input_method===''">
      <p style="color: red; margin-left: 350px;"> Method를 선택해 주세요. </p>
    </template>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        type="primary"
        size="small"
        html-type="submit"
        align="center"
      >
        CREATE
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      exchange: [
        
      ],
      currency_list: [

      ],
      server_list: [
        {
            label: 'Master',
            value: 'master',
        },
        {
            label: 'LP1',
            value: 'lp1',
        },
        {
            label: 'LP2',
            value: 'lp2',
        },
        {
            label: 'LP3',
            value: 'lp3',
        },
        {
            label: 'LP4',
            value: 'lp4',
        },
        {
            label: 'LP5',
            value: 'lp5',
        },
        {
            label: 'LP6',
            value: 'lp6',
        },
        {
            label: 'Dev1',
            value: 'lp7',
        },
        {
            label: 'Dev2',
            value: 'lp8',
        },
      ],
      method_list:
      [
          {label: 'Select', value: 'select'},
          {label: 'Manual', value: 'manual'},
      ],
      input_method: '',
      is_currency_loading: true,
      runserver: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "depositaddlist" });
  },
  async mounted() {
    await this.getExchange()
  },
  methods: {
    async get_symbols(e){
      // console.log(e)
      this.is_currency_loading = true
      const runserverValue = this.form.getFieldValue('runserver');
      const res = await this.$http.get(`/api/v1/exchange2/${e}/symbols/${runserverValue}`)
      this.currency_list = res.data
      this.is_currency_loading = false
      // console.log(this.currency_list)
    },
    async getExchange() {
        try {
            const res = await this.$http.get('/api/v1/info/exchange')
            for (const [key, value] of Object.entries(res.data)) {
                this.exchange.push({
                    label: key,
                    value
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values)
        if (!err) {
          // console.log("Received values of form: ", values);
          this.$http
            .post(`/api/v1/depositadd/`, {
              name: values.name,
              exchange: values.exchange.toLowerCase(),
              token: values.token,
              network: values.network,
              address: values.address,
              description: values.description,
              input_method: this.input_method,
              runserver: values.runserver,
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    changeMethod(v) {
      this.input_method = v;
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      // this.$router.go("/pages/UserManagement/UserList");
      this.$router.go();
      // this.$router.push(`/api/v1/apikeys`);
    },
  },
};
</script>

<style></style>
